import { render, staticRenderFns } from "./SaleItem.vue?vue&type=template&id=74b0f8a8"
import script from "./SaleItem.vue?vue&type=script&lang=js"
export * from "./SaleItem.vue?vue&type=script&lang=js"
import style0 from "./SaleItem.vue?vue&type=style&index=0&id=74b0f8a8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports